<template>
  <div>
    <div class="my-6 text-start">
      <h3 class="font-bold">Criar um novo evento</h3>
      <span class="text-sm text-gray-500">Preencha as informações abaixo e clique no botão <b>criar</b> para
        salvar as alterações.
      </span>
    </div>
    <div class="card shadow-sm border p-8 bg-white">
      <div class="flex lg:flex-row flex-col">
      <div class="w-full p-2">
        <Input type="default" v-model="data.nome" name="nome" label="Nome" placeholder="Nome do evento" data-test="input-nome"/>
      </div>
      <div class="w-full p-2" @focusout="setData">
        <Input type="customDate" :placeholder="'Dia/Mês/Ano'" :name="'dataEvento'" id="data_evento" v-model="data.data" label="Data" 
        />
      </div>
      <div class="w-full p-2">
        <Input v-model="data.taxa_sc" id="taxa" :name="'taxa'" type="tax" label="Taxa Santo Cartão (0 a 100%)" placeholder="Taxa" />
      </div>
      </div>

      <div class="flex lg:flex-row flex-col">
        <div class="w-full p-2">
        <label class="relative">
          <span class="text-gray-700 bg-white px-3 -top-2 left-4 absolute text-sm z-40">
            Organizador
          </span>
          <Multiselect v-model="data.organizador" :options="organizador" @tag="addOrganizador"
            placeholder="Procure ou Adicione um Organizador para o Evento" :multiple="false" :taggable="true"
            :closeOnSelect="true" label="label" id="organizador">
          </Multiselect>
        </label>
      </div>

      <div class="w-full p-2">
        <label class="block relative">
          <span class="text-gray-700 bg-white px-3 -top-2 left-4 absolute text-sm z-40">
            Check-in
          </span>
          <Multiselect v-model="data.checking" :options="checkIn" @tag="addCheking"
            placeholder="Procure ou Adicione um Checking para o Evento" :multiple="false" :taggable="true" label="label"
            :closeOnSelect="true" id="checkin">
          </Multiselect>
        </label>
      </div>
    </div>

    <div class="flex lg:flex-row flex-col">
      <div class="w-full p-2">
        <Input type="customDate" :placeholder="'Dia/Mês/Ano'" id="data_evento_inicio" v-model="data.data_inicio" label="Data de inicio do evento"
          />
      </div>

      <div class="w-full p-2">
        <Input v-model="data.hora_inicio" id="Inicio" type="customTime" label="Hora de Inicio do Evento"
          placeholder="Hora do Inicio do Evento" :name="'horaInicioEvento'" />
      </div>
    </div>

    <div class="flex lg:flex-row flex-col">
      <div class="w-full p-2">
        <Input type="customDate" :placeholder="'Dia/Mês/Ano'" id="data_evento" v-model="data.data_encerramento"
          label="Data de Encerramento do Evento" :name="'dataEncerramentoEvento'"/>
      </div>

      <div class="w-full p-2">
        <Input v-model="data.hora_encerramento" id="Encerramento" type="customTime"
          label="Hora de Encerramento do Evento" placeholder="Hora de Encerramento" :name="'horaEncerramentoEvento'"/>
      </div>

    </div>

    <div class="flex lg:flex-row flex-col">
      <div class="w-full p-2 lg:max-w-sm xl:max-w-lg">
        <label for="regras" class="block relative">
        <span class="text-gray-700 bg-white px-3 -top-2 left-4 absolute text-sm">
        Regras do Evento
        </span>  
        <QuillEditor theme="snow" :toolbar="toolbarOptions" :options="editorOptions" v-model:content="data.regras"
          id="regras" style="height: 200px;" class="" contentType="html" name="regrasEvento"/>
        </label>
      </div>
      
      <div class="w-full p-2">
        <div class="flex flex-col flex-1 mt-1 mx-3 gap-3 mt-6 items-center">
          <img class="flex-auto rounded-lg shadow-lg w-36 h-36"
            :src="data.imagem ? readURL(data.imagem) : 'https://via.placeholder.com/400x400'" />
          <button class="
                    bg-primary
                    rounded-md
                    shadow-md               
                    font-bold
                    flex
                    justify-items
                    items-center      
                    px-4
                    py-2
                    btnImg
                  " name="imageAdd">
            <label class="text-white" for="logo">Adicionar</label>
            <i class="ml-3 text-white gg-camera"></i>
            <input @change="changeImage" class="absolute opacity-0 bton" id="logo" type="file" ref="button" name="selectImage"
              style=" left: -1px; bottom: -35px;" />
          </button>
        </div>
      </div>
    </div>

    <div class="block p-1 m-2  h-20">
      <div class="flex-1 my-3">
        <label>
          <input type="checkbox" v-model="data.show_organizador" class="mr-2" />
          Inserir Informações do Organizador no Voucher
        </label>
      </div>
    </div>


    <div class="flex justify-end mt-5">
      <Button @click="create" color="primary" text="Criar" id="criar"></Button>
    </div>
    </div>


  </div>
</template>

<script>
import Input from "../../components/Elements/Input.vue";
import Button from "../../components/Elements/Button.vue";
import Multiselect from 'vue-multiselect'
import moment from "moment";
import { inject, ref, onMounted, watch } from "vue";
import { GET, POST } from "../../services/api";
import { isEmpty } from "../../services/validate";
import { useRoute, useRouter } from "vue-router";
import { dataAtualFormatada, dateBrtoStamp, dateWithTimeBrtoStamp, convertToUTC, formatDate } from "@/services/formater";
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';



export default {
  name: "CriarEvento",
  components: {
    Input,
    Button,
    Multiselect,
    QuillEditor,
  },
  emits: ['blur'],
  data() {
    return {
      isMobile: false,
      toolbarOptions: [
        ['bold', 'italic', 'underline'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      ],
      editorOptions: {
        modules: {
          autoformat: {
            remove: false,
            rules: [
              { newline: true, format: [] }
            ]
          }
        }
      },
      disabled: 1,
    };
  },

  setup() {
    const data = ref({
      nome: "",
      "taxa_sc": 0,
      data: "",
      "data_encerramento": "",
      "hora_encerramento": "",
      "data_inicio": "",
      "hora_inicio": "",
      organizador: "",
      checking: "",
      "show_organizador": true,
      imagem: "",
      regras: "",
    });
    const organizador = ref([]);
    const checkIn = ref([]);
    const loader = inject("loading");
    const alert = inject("alert");
    const router = useRouter();
    const route = useRoute();
    const today = ref(dataAtualFormatada("us"));

    const create = async () => {
      const usuarioConta = JSON.parse(localStorage.getItem('info_santo_cartao_adm'))

      const obj = {
        ...data.value,
        "id_usuario": data.value.organizador.value,
        "id_checking": data.value.checking.value,
        "show_promoters_document": (data.value["show_organizador"]) ? 1 : 0,
        "data": dateBrtoStamp(data.value['data']),
        "data_hora_parada": convertToUTC(data.value["data_inicio"], data.value["hora_inicio"], usuarioConta.timeZone),
        "data_inicio": convertToUTC(data.value["data_inicio"], data.value["hora_inicio"], usuarioConta.timeZone),

        "data_fim": convertToUTC(data.value["data_encerramento"], data.value["hora_encerramento"], usuarioConta.timeZone),
        "data_site": convertToUTC(data.value["data_inicio"], data.value["hora_inicio"], usuarioConta.timeZone),
        "data_voucher": convertToUTC(data.value["data_inicio"], data.value["hora_inicio"], usuarioConta.timeZone),
        "data_fisico": convertToUTC(data.value["data_inicio"], data.value["hora_inicio"], usuarioConta.timeZone),
      }

      delete obj.hora_encerramento
      delete obj.data_encerramento
      delete obj.hora_inicio
      delete obj.organizador
      delete obj.checking
      delete obj.show_organizador

      delete data.value.relatorio;

      if(!obj.regras){
          delete obj.regras
          delete data.value.regras
        }
      const validate = isEmpty(data.value);
      if (validate) {
        alert.open("Atenção!", validate, "warning");
      } else {
        const dataValida = (today.value <= obj.data) ? true : false
        if (dataValida) {
          loader.open();
          const objRequest = new FormData();
          const item = obj;
          for (const key in item) {
            objRequest.append(key, item[key]);
          }


          try {
            await POST("event", objRequest);
            //localStorage.setItem('cyEvento', response.identifiers[0].id)
            alert.open(
              "Sucesso!",
              `Evento <b>${data.value.nome}</b> criado com sucesso!`,
              "success"
            );
            loader.close();
            router.push("/eventos");
          } catch (e) {
            const erroPadrao = 'SC[101] Não foi possível estabelecer conexão, verifique sua conexão com a internet!!'
            if(e !== `TypeError: Cannot read properties of undefined (reading 'status')`)
              alert.open("Atenção!", e , "danger");
            else{
              alert.open("Atenção!", erroPadrao , "danger");
            }
            loader.close();
          }

        } else {
          alert.open("Atenção!", "[SC102] Data de evento Inválida, a data do evento deve ser igual ou posterior à data de hoje.", "warning");
        }
      }
    };

    const changeImage = (event) => {
      const obj = {
        nome: data.value.nome,
        "taxa_sc": data.value.taxa_sc,
        data: data.value.data,
        "data_encerramento": data.value["data_encerramento"],
        "hora_encerramento": data.value["hora_encerramento"],
        "data_inicio": data.value["data_inicio"],
        "hora_inicio": data.value["hora_inicio"],
        organizador: data.value["organizador"],
        checking: data.value["checking"],
        "show_organizador": data.value["show_organizador"],
        regras: data.value.regras,
        imagem: event.target.files[0],
      }
      data.value = obj;
    }

    const readURL = (file) => {
      if (typeof file == "string") {

        return file;
      }
      return window.URL.createObjectURL(file);
    };

    onMounted(async () => {
      loader.open();

      const usuariosRes = await POST("usuarios/filter", { perfil: [1, 5] });
      usuariosRes.data.forEach((usuario) => {
        const isOrganizador = usuario.evento_usuario_perfil.findIndex(perfil => perfil['id_perfil'] === 1)

        if (isOrganizador != -1) {
          organizador.value.push({ label: usuario.usuario, value: usuario.id })
        } else {
          checkIn.value.push({ label: usuario.usuario, value: usuario.id })
        }
      });
      loader.close();

    });

    const setData = () => {
      data.value["data_inicio"] = data.value.data;
      data.value["data_encerramento"] = data.value.data;
    }

    const uploading = () => {

      document.getElementById("logo").click()
    }

    const addOrganizador = (newTag) => {
      const tag = {
        label: newTag,
        value: newTag
      }

      organizador.value.push(tag)

      data.value.organizador = tag
    }

    const addCheking = (newTag) => {
      const tag = {
        label: newTag,
        value: newTag
      }

      checkIn.value.push(tag)

      data.value.checking = tag
    }
    return {
      data,
      create,
      route,
      readURL,
      today,
      changeImage,
      uploading,
      addOrganizador,
      addCheking,
      organizador,
      checkIn,
      setData,
    };
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style>
.btn {
  width: 100%;
  align-items: center;
  justify-content: center;
}

.bton {
  z-index: 5;
  width: 117px;
  height: 75px;
}

input[type="file"]::-webkit-file-upload-button {
  height: 40px;
}

.btnImg {
  width: max-content;
}
</style>